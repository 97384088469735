var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.manufacturer != null)?_c('v-container',{staticClass:"px-6 pt-2",staticStyle:{"background":"#eceff1"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"my-0",attrs:{"justify":"center","align":"center"}},[_c('v-col',[_c('h3',[_vm._v(" List of equipment that are manufactured by: "),_c('b',[_vm._v(_vm._s(_vm.manufacturer.name))])])]),_c('v-col',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"},attrs:{"lg":"5","md":"2","sm":"6"}},[_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
          'max-width':
            _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
              ? '200px'
              : '110px',
        }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"dense":"","headers":_vm.headers,"items":_vm.entities,"server-items-length":_vm.total,"items-per-page":15,"loading":_vm.loadingStates.table,"options":_vm.options,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:'equipment-image-' + item.id},'div',attrs,false),on),[_c('div',{staticClass:"equipment-img"},[(item.imageUrl != null && item.imageUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.id + '_img',attrs:{"src":item.imageUrl,"height":"100%","width":"100%"}})]):(item.category != null)?_c('i',{class:'fad ' + item.category.icon}):_c('i',{class:'fad fa-plug'})])])]}}],null,true)},[_c('span',[((item.imageUrl != null) & (item.imageUrl != ''))?_c('v-img',{staticClass:"img",attrs:{"src":item.imageUrl,"width":"250px","height":"250px","contain":""}}):(item.category != null)?_c('i',{class:'fad ' + item.category.icon,staticStyle:{"font-size":"32px","padding":"2rem"}}):_c('i',{class:'fad fa-plug',staticStyle:{"font-size":"32px","padding":"2rem"}})],1)])]}},{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-content-start align-items-center",attrs:{"sm":"auto"}},[(item.category != null)?_c('i',{class:'equipment-icon fad ' + item.category.icon}):_c('i',{class:'equipment-icon fad fa-plug'})]),_c('v-col',{staticClass:"d-flex align-center"},[(item.category)?_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(item.category.name))]):_vm._e()])],1)]}},{key:"item.tag",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"equipment-tag font-weight-bold"},[_vm._v(_vm._s(item.tag))])]}},{key:"item.manufacture.name",fn:function(ref){
    var item = ref.item;
return [(item.manufacture != null)?_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"0.5rem"}},[_c('v-tooltip',{attrs:{"right":"","z-index":"999","nudge-right":"-4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',{staticClass:"company-logo-mini"},[(item.manufacture.logoUrl != null && item.manufacture.logoUrl != '')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":item.manufacture.logoUrl,"height":"100%","width":"100%"}})]):_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticStyle:{"height":"100%","width":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[_c('img',{key:item.manufacture.id + '_img',staticStyle:{"object-fit":"contain"},attrs:{"src":"/img/DNA_Symbol.png","height":"100%","width":"100%"}})])])])]}}],null,true)},[_c('span',[(item.manufacture.logoUrl != null && item.manufacture.logoUrl != '')?_c('v-img',{attrs:{"src":item.manufacture.logoUrl,"height":"250px","width":"250px","contain":""}}):_c('v-img',{attrs:{"src":"/img/DNA_Symbol.png","height":"250px","width":"250px","contain":""}})],1)]),_c('h5',{staticClass:"ma-0"},[_vm._v(_vm._s(item.manufacture.name))])],1):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.productFamily.name",fn:function(ref){
    var item = ref.item;
return [(item.productFamily != null)?_c('h5',{staticClass:"ma-0"},[_vm._v(" "+_vm._s(item.productFamily.name)+" ")]):_c('div',{staticClass:"text--disabled"},[_vm._v("N/A")])]}},{key:"item.model",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"equipment-model mono-font font-weight-bold"},[_vm._v(_vm._s(item.model))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"color":"secondary","icon":"","elevation":"0"},on:{"click":function($event){return _vm.viewEquipment(item)}}},[_c('i',{staticClass:"fad fa-info"})])]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('edit-equipment',{ref:"viewEquipment",attrs:{"nested":""}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }