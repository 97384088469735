export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    sortable: false,
    order: 0,
    hidable: true,
    hidden: false,
    width: "60px",
  },
  { text: "Name", value: "name", width: "260px", order: 1, hidable: false, hidden: false },
  {
    text: "Abbrv.",
    value: "abbreviatedName",
    order: 2,
    hidable: true,
    hidden: false,
    width: "90px",
  },
  {
    text: "Industry",
    value: "industryType",
    order: 3,
    hidable: true,
    hidden: false,
    width: "120px",
  },
  {
    text: "Contacts",
    value: "contactsCount",
    order: 4,
    hidable: true,
    hidden: false,
    width: "110px",
  },
  {
    text: "Product Families",
    value: "productFamiliesCount",
    order: 5,
    hidable: true,
    hidden: false,
    width: "150px",
  },
  {
    text: "Stats",
    value: "stats",
    width: "100px",
    order: 6,
    hidable: true,
    hidden: false,
    sortable: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 7,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Updated",
    value: "updateDate",
    width: "130px",
    order: 8,
    hidable: true,
    hidden: false,
  },
  {
    text: "Created By",
    value: "createdBy",
    width: "120px",
    order: 9,
    hidable: true,
    hidden: true,
  },
  {
    text: "Updated By",
    value: "updatedBy",
    width: "120px",
    order: 10,
    hidable: true,
    hidden: true,
  },
];
