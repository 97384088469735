<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 950"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :visible.sync="slideouts.manageProductFamilies.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching Product Families ...
      </h3>
      <h3 v-else-if="manufacturer != null" class="font-weight-bold pa-1" small>
        <span>
          <i class="fad fa-user-tie mr-2"></i>
          <b>{{ manufacturer.name }}</b> Product Families
        </span>
        <entity-id
          class="ml-2"
          :label="manufacturer.id"
          :path="manufactureProductFamiliesUrl"
          title="Manufacture Product Families"
        ></entity-id>
      </h3>
      <div>
        <panel-size-control
          v-model="slideouts.manageProductFamilies.fullWidth"
        ></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>
    <div v-if="slideouts.manageProductFamilies.active">
      <v-container fluid>
        <div style="background: #eceff1; border-radius: 0.5rem" class="pa-5 inset-shadow">
          <div class="d-flex align-center" style="gap: 1rem">
            <h3 class="text--primary font-weight-black my-1">
              Product Families<v-chip
                v-if="!isFetching"
                class="ml-2 font-weight-bold"
                small
                label
                >{{ productFamilies.length }}</v-chip
              >
            </h3>
            <v-btn
              color="info"
              ref="addProductFamily"
              small
              @click="createProductFamily"
              v-if="
                !isFetching &&
                $has(perms.Manufacturers.Update) &&
                manufacturer.name != specialManufacturerName
              "
            >
              <span class="fs-12px"><i class="far fa-plus mr-2"></i>Create</span>
            </v-btn>
          </div>
          <v-data-table
            dense
            :headers="headers"
            :items="productFamilies"
            :items-per-page="options.itemsPerPage"
            @click:row="rowClicked"
            :loading="loadingStates.table"
            class="productFamilies-table elevation-2 mt-2"
            hide-default-footer
          >
            <template v-slot:progress>
              <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
            </template>

            <template v-slot:[`item.name`]="{ item }">
              <h4
                class="ma-0 deep-orange--text"
                v-if="manufacturer.name == specialManufacturerName"
              >
                {{ item.name }}
              </h4>
              <h4 class="ma-0" v-else>{{ item.name }}</h4>
            </template>

            <template v-slot:[`item.createDate`]="{ item }">
              <dater :date="item.createDate" dateonly></dater>
            </template>

            <template v-slot:[`item.updateDate`]="{ item }">
              <dater :date="item.updateDate" dateonly></dater>
            </template>

            <template v-slot:[`item.createdBy`]="{ item }">
              <user-avatar :user="item.createdBy" icon></user-avatar>
            </template>

            <template v-slot:[`item.updatedBy`]="{ item }">
              <user-avatar :user="item.updatedBy" icon></user-avatar>
            </template>

            <template v-slot:[`item.actions`]="{ item, index }">
              <v-menu dense offset-x right v-if="manufacturer.name != specialManufacturerName">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    icon
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                    v-if="manufacturer.name != specialManufacturerName"
                  >
                    <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
                  </v-btn>
                </template>
                <v-list class="more-options-menu">
                  <v-list-item
                    @click="updateProductFamily(item, index)"
                    v-if="$has(perms.Manufacturers.Update)"
                  >
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-pen</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Update</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="del(item)" v-if="$has(perms.Manufacturers.Delete)">
                    <v-list-item-icon class="mr-2 justify-center">
                      <v-icon small>fal fa-trash-alt red--text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="red--text">Delete</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:loading>
              <video width="250" muted loop autoplay style="padding: 0.5rem">
                <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
              </video>
              <p class="font-weight-bold">Searching the Cosmos...</p>
            </template>
            <template v-slot:no-data>
              <img width="300" src="/img/art/fogg-no-connection-2.png" />
              <p class="font-weight-bold">No Data Available!</p>
            </template>
          </v-data-table>
        </div>
      </v-container>
      <edit-product-family
        nested
        :manufacturer-id="options.manufacturerId"
        ref="editProductFamily"
        @save="onProductFamilySaved"
        @close="onProductFamilyClosed"
        @delete="onProductFamilyDeleted"
      ></edit-product-family>
    </div>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="closeUpdateSlideout()">
          <i class="fal fa-chevron-left mr-2"></i>Close
        </v-btn>
      </v-card-actions>
    </template>
  </slideout>
</template>

<script>
import perms from "../../../../plugins/permissions";
import Enums from "../../../../plugins/enums";
import productFamiliesService from "../services/productFamilies-service.js";
import productFamiliesHeader from "../config/tables/productFamilies.header";
import EditProductFamily from "./EditProductFamily.vue";
import PanelSizeControl from "../../../Shared/components/PanelSizeControl.vue";

export default {
  name: "manage-product-families",
  data() {
    return {
      specialManufacturerName: "**NO ASSOCIATED MAKE**",
      perms: perms,
      states: this.$options.filters.EnumToList(Enums.clientStates),
      headers: productFamiliesHeader,
      isFetching: true,
      productFamiliesTimerId: null,
      valid: false,
      manufacturer: null,
      isInitiallyLoaded: false,
      productFamilies: [],
      total: 0,
      options: {
        manufacturerId: null,
        search: null,
        page: 1,
        itemsPerPage: -1,
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
      },
      loadingStates: {
        table: false,
      },
      slideouts: {
        manageProductFamilies: {
          active: false,
          fullWidth: false,
          isLoading: false,
        },
      },
      storedSearch: false,
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  computed: {
    manufactureProductFamiliesUrl() {
      if (this.manufacturer == null) return "";
      return `manufacturers/${this.manufacturer.id}/product-families`;
    },
    mainPanelSize() {
      return this.slideouts.manageProductFamilies.fullWidth
        ? "100%"
        : this.nested
        ? "87%"
        : "950px";
    },
  },
  methods: {
    open(id, editMode = false) {
      this.manufacturer = null;
      this.productFamilies = [];
      this.total = 0;
      this.isInitiallyLoaded = false;
      this.isFetching = true;
      this.options = {
        search: null,
        manufacturerId: id,
        includeManufacturer: false,
        page: 1,
        itemsPerPage: -1,
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
      };
      this.slideouts.manageProductFamilies.active = true;
    },
    getData() {
      this.loadingStates.table = true;
      let optionsToSend = this.cloneDeep(this.options);
      if (this.storedSearch != optionsToSend.search) optionsToSend.page = 0;
      this.storedSearch = optionsToSend.search;
      if (!this.isInitiallyLoaded) {
        this.isFetching = true;
        optionsToSend.includeManufacturer = true;
      }
      this.$log("optionsToSend", optionsToSend);
      productFamiliesService
        .query(this.$clean(optionsToSend, true))
        .then((resp) => {
          this.$log("resp.data.items", resp.data.items);
          this.productFamilies = resp.data.items;
          this.total = resp.data.total;
          if (!this.isInitiallyLoaded) {
            this.isInitiallyLoaded = true;
            this.isFetching = false;
            this.manufacturer = resp.data.manufacturer;
          }
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.isFetching = false;
          this.$handleError(err);
        });
    },
    getDataDebounced() {
      if (this.productFamiliesTimerId == null) {
        this.productFamiliesTimerId = -1;
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.productFamiliesTimerId);

      // delay new call 400ms
      this.productFamiliesTimerId = setTimeout(() => {
        this.getData();
      }, 400);
    },
    onSlideoutClosing(e) {
      this.$emit("close");
    },
    closeUpdateSlideout() {
      this.slideouts.manageProductFamilies.active = false;
    },
    createProductFamily() {
      this.$refs.editProductFamily.open(null);
    },
    updateProductFamily(productFamily, index) {
      setTimeout(() => {
        this.$refs.editProductFamily.open(productFamily.id, true);
      });
    },
    cloneProductFamily(productFamily, index) {
      this.$refs.editProductFamily.clone(productFamily.id, true);
    },
    viewProductFamily(id) {
      this.$log("viewProductFamily", id, this.$refs.editProductFamily);
      this.$refs.editProductFamily.open(id);
    },
    rowClicked(row) {
      if (this.manufacturer.name == this.specialManufacturerName) return;
      this.$log("rowClicked", row);
      this.viewProductFamily(row.id);
    },
    onProductFamilyClosed() {},
    onProductFamilySaved(productFamilyData, isNewProductFamily) {
      this.$log("onProductFamilySaved", productFamilyData, isNewProductFamily);
      if (isNewProductFamily) {
        this.addToArr(this.productFamilies, productFamilyData);
      } else {
        this.updateArr(this.productFamilies, productFamilyData);
      }
    },
    del(productFamily) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete "<b>${productFamily.name}</b>" from product families?`,
          title: `Delete a Product Family?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return productFamiliesService
                  .delete(productFamily.id)
                  .then((resp) => {
                    this.onProductFamilyDeleted(productFamily.id);
                    this.$dialog.notify.success("Product Family deleted successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onProductFamilyDeleted(id) {
      const index = this.productFamilies.indexOf(this.productFamilies.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.productFamilies.splice(index, 1);
      }
    },
  },
  watch: {
    options: {
      handler(val) {
        this.getDataDebounced();
      },
      deep: true,
    },
  },
  components: { EditProductFamily, PanelSizeControl },
};
</script>

<style lang="scss">
.productFamilies-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}
</style>
