export default [
  {
    text: "Action",
    value: "actions",
    width: "40px",
    sortable: false,
    hidable: false,
    hidden: false,
  },
  {
    text: "Equipment",
    value: "name",
    width: "80px",
    sortable: false,
    hidable: false,
    hidden: false,
  },
  {
    text: "Category",
    value: "category",
    width: "120px",
    sortable: false,
    hidable: false,
    hidden: false,
  },
  { text: "Tag", value: "tag", width: "120px", hidable: true, hidden: false },
  {
    text: "Manufacturer",
    width: "150px",
    value: "manufacture.name",
    hidable: true,
    hidden: false,
  },
  {
    text: "Family",
    width: "150px",
    value: "productFamily.name",
    hidable: true,
    hidden: false,
  },
];
