import Api from "../../../Shared/services/api";
import ApiUploader from "../../../Shared/services/apiUploader";

const baseUrl = "Companies";
export default {
  exportTemplate(type) {
    return Api().get(`${baseUrl}/Export-Template/${type}`, {
      responseType: "blob",
    });
  },
  import(fileData, config) {
    return Api().post(`${baseUrl}/Import`, fileData, config);
  },
  convertToClient(id, value = true) {
    return Api().put(`${baseUrl}/${id}/ToClient/${value}`);
  },
};
